import { isFriday } from 'date-fns';
import { eachDayOfInterval, startOfDay } from 'date-fns/esm/fp';

const createDate = date => startOfDay(new Date(date));

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
const getContinuousDaysInRange = (min, max) => {
  if (!isValidDate(min) || !isValidDate(max)) return [];
  return eachDayOfInterval({ start: min, end: max }).map(date =>
    startOfDay(date)
  );
};
const CATEGORIES = {
  SHARADA: 'Sharada Temple',
  SHIVA: 'Shiva Temple',
  GANESH: 'Ganapathi Temple',
  SPECIAL_EVENTS: 'Special Events',
  OTHERS: 'Special General Poojas',
  DASARA_NAVARATRI: 'Dasara Navaratri Poojas',
  GANAPATHI_NAVARATRI: 'Ganapthi Navaratri Poojas',
  MAHASHIVARATRI: 'Maha Shivaratri Special Poojas'
};

export const poojaInfo = [
  // 1) Rudra Abhishekam
  {
    label: 'Rudra Abhishekam',
    name: 'Shiva Abhishekam',
    amount: '150',
    availableDates: [],
    category: CATEGORIES.SHIVA
  },
  // 2) Aksharabhyasam
  {
    label: 'Aksharabhyasam',
    name: 'Aksharabhyasam',
    amount: '100',
    availableDates: [],
    category: CATEGORIES.OTHERS
  },
  // 3) Naivedya Sahasram
  {
    label: 'Naivedya Sahasram',
    name: 'Naivedya Sahasram',
    amount: '100',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  // 4) Pushpa Alankara Seva
  {
    label: 'Pushpa Alankara Seva',
    name: 'Pushpa Alankara Seva',
    amount: '500',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },

  // ------------------------------------------------------------------
  // 5) Pradoshabhishekam (Pradosha Puja) - TWO DATES PER MONTH in 2025
  // ------------------------------------------------------------------
  {
    name: 'Pradoshabhishekam',
    amount: '150',
    // 2025 DATES (example from your table)
    availableDates: [
      createDate('01-11-2025'), createDate('01-27-2025'),
      createDate('02-10-2025'), createDate('02-25-2025'),
      createDate('03-11-2025'), createDate('03-27-2025'),
      createDate('04-10-2025'), createDate('04-25-2025'),
      createDate('05-09-2025'), createDate('05-24-2025'),
      createDate('06-08-2025'), createDate('06-23-2025'),
      createDate('07-08-2025'), createDate('07-22-2025'),
      createDate('08-06-2025'), createDate('08-20-2025'),
      createDate('09-05-2025'), createDate('09-19-2025'),
      createDate('10-04-2025'), createDate('10-18-2025'),
      createDate('11-03-2025'), createDate('11-17-2025'),
      createDate('12-02-2025'), createDate('12-17-2025')
    ],
    category: CATEGORIES.SHIVA
  },

  // ------------------------------------------------
  // 6) Ganapathi Homam (Shukla Chavithi) - 2025
  // ------------------------------------------------
  {
    label: 'Ganapathi Homam  (Shukla Chavithi)',
    name: 'Ganapathi Homam',
    amount: '250',
    // 2025 DATES
    availableDates: [
      createDate('01-03-2025'),
      createDate('02-02-2025'),
      createDate('03-03-2025'),
      createDate('04-02-2025'),
      createDate('05-01-2025'),
      createDate('05-30-2025'),
      createDate('06-29-2025'),
      createDate('07-28-2025'),
      createDate('08-27-2025'),
      createDate('09-25-2025'),
      createDate('10-25-2025'),
      createDate('11-24-2025'),
      createDate('12-24-2025')
    ],
    category: CATEGORIES.GANESH,
    information: 'Performed Every month on Shukla Chavithi'
  },

  // 7) Ganapathi Navaratri Homam (unchanged)
  {
    label: 'Ganapathi Navaratri Homam',
    name: 'Ganapathi Navaratri Homam',
    amount: '250',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('09-11-2021'),
        new Date('09-18-2021')
      )
    ],
    category: CATEGORIES.GANESH,
    information: 'Performed only in Ganapathy Navaratri days'
  },
  // 8) Sahashra Modaka Ganapathi (unchanged)
  {
    label: 'Sahashra Modaka Ganapathi Homa Sahitha Abhishekam',
    name: 'Sahashra modaka ganapathi homa sahitha abhishekam',
    amount: '300',
    availableDates: [createDate('09-18-2023')],
    category: CATEGORIES.GANESH,
    information:
      'Performed only in Vinayaka Chavithi (Bhadra pada shuddha chaviti)'
  },

  // ---------------------------------------------------------
  // 9) Guru Paduka Pooja (Every Shukla Shasti) - 2025
  // ---------------------------------------------------------
  {
    label: 'Guru Paduka Pooja (Every Shukla Shasti)',
    name: 'Guru Paduka Pooja',
    amount: '300',
    // 2025 DATES
    availableDates: [
      createDate('01-05-2025'),
      createDate('02-04-2025'),
      createDate('03-05-2025'),
      createDate('04-03-2025'),
      createDate('05-03-2025'),
      createDate('06-01-2025'),
      createDate('07-01-2025'),
      createDate('07-30-2025'),
      createDate('08-29-2025'),
      createDate('09-28-2025'),
      createDate('10-27-2025'),
      createDate('11-26-2025'),
      createDate('12-26-2025')
    ],
    category: CATEGORIES.SPECIAL_EVENTS
  },

  // ---------------------------------------------------------
  // 10) Chandi Parayana (Shukla Saptami - Pournami) - 2025
  // ---------------------------------------------------------
  {
    label: 'Parayana / Homam (Shukla Saptami - Pournami)',
    name: 'Chandi Parayana',
    amount: '500',
    availableDates: [
      // January 6 – 14
      ...getContinuousDaysInRange(
        new Date('01-06-2025'),
        new Date('01-14-2025')
      ),
      // February 4 – 12
      ...getContinuousDaysInRange(
        new Date('02-04-2025'),
        new Date('02-12-2025')
      ),
      // March 6 – 14
      ...getContinuousDaysInRange(
        new Date('03-06-2025'),
        new Date('03-14-2025')
      ),
      // April 4 – 12
      ...getContinuousDaysInRange(
        new Date('04-04-2025'),
        new Date('04-12-2025')
      ),
      // May 4 – 12
      ...getContinuousDaysInRange(
        new Date('05-04-2025'),
        new Date('05-12-2025')
      ),
      // June 2 – 10
      ...getContinuousDaysInRange(
        new Date('06-02-2025'),
        new Date('06-10-2025')
      ),
      // July 2 – 10 (first July start date)
      ...getContinuousDaysInRange(
        new Date('07-02-2025'),
        new Date('07-10-2025')
      ),
      // July 31 – August 8 (second July start date)
      ...getContinuousDaysInRange(
        new Date('07-31-2025'),
        new Date('08-08-2025')
      ),
      // August 30 – September 7
      ...getContinuousDaysInRange(
        new Date('08-30-2025'),
        new Date('09-07-2025')
      ),
      // September 29 – October 7
      ...getContinuousDaysInRange(
        new Date('09-29-2025'),
        new Date('10-07-2025')
      ),
      // October 28 – November 5
      ...getContinuousDaysInRange(
        new Date('10-28-2025'),
        new Date('11-05-2025')
      ),
      // November 26 – December 4
      ...getContinuousDaysInRange(
        new Date('11-27-2025'),
        new Date('12-04-2025')
      ),
      // December 27 – January 4 (2026)
      ...getContinuousDaysInRange(
        new Date('12-27-2025'),
        new Date('01-04-2026')
      ),
    ],
    category: CATEGORIES.SPECIAL_EVENTS
  },
  // ---------------------------------------------------------
  // 11) Satyanarayana Swamy Vratam - 2025
  // ---------------------------------------------------------
  {
    name: 'Satyanarayana Swamy Vratam',
    amount: '200',
    // 2025 DATES
    availableDates: [
      createDate('01-10-2025'),
      createDate('02-08-2025'),
      createDate('03-10-2025'),
      createDate('04-08-2025'),
      createDate('05-08-2025'),
      createDate('06-06-2025'),
      createDate('07-06-2025'),
      createDate('08-05-2025'),
      createDate('09-03-2025'),
      createDate('10-03-2025'),
      createDate('11-01-2025'),
      createDate('12-01-2025'),
      createDate('12-30-2025')
    ],
    category: CATEGORIES.SPECIAL_EVENTS
  },

  // ---------------------------------------------------------
  // 12) Pournami Laksha Kumukumarchana - 2025
  // ---------------------------------------------------------
  {
    name: 'Pournami Laksha Kumukumarchana',
    amount: '4000',
    // 2025 DATES
    availableDates: [
      createDate('01-13-2025'),
      createDate('02-12-2025'),
      createDate('03-14-2025'),
      createDate('04-12-2025'),
      createDate('05-12-2025'),
      createDate('06-11-2025'),
      createDate('07-10-2025'),
      createDate('08-09-2025'),
      createDate('09-07-2025'),
      createDate('10-07-2025'),
      createDate('11-05-2025'),
      createDate('12-04-2025')
    ],
    category: CATEGORIES.SHARADA
  },

  // ---------------------------------------------------------
  // 13) Sankata Hara Chaturdi (Bahula Chavithi) - 2025
  // ---------------------------------------------------------
  {
    label: 'Sankata Hara Chaturdi (Bahula Chavithi)',
    name: 'Sankata Hara Chaturdi',
    amount: '150',
    // 2025 DATES
    availableDates: [
      createDate('01-17-2025'),
      createDate('02-16-2025'),
      createDate('03-17-2025'),
      createDate('04-16-2025'),
      createDate('05-16-2025'),
      createDate('06-14-2025'),
      createDate('07-14-2025'),
      createDate('08-12-2025'),
      createDate('09-10-2025'),
      createDate('10-10-2025'),
      createDate('11-08-2025'),
      createDate('12-08-2025')
    ],
    category: CATEGORIES.GANESH
  },

  // ---------------------------------------------------------
  // 14) Masa Sivaratri / Rudra Homam - 2025
  // ---------------------------------------------------------
  {
    label: 'Masa Sivaratri / Rudra Homam',
    name: 'Masa Sivaratri Rudra Homam',
    amount: '300',
    // 2025 DATES
    availableDates: [
      createDate('01-28-2025'),
      createDate('02-26-2025'),
      createDate('03-28-2025'),
      createDate('04-26-2025'),
      createDate('05-25-2025'),
      createDate('06-24-2025'),
      createDate('07-23-2025'),
      createDate('08-21-2025'),
      createDate('09-20-2025'),
      createDate('10-19-2025'),
      createDate('11-18-2025'),
      createDate('12-18-2025')
    ],
    category: CATEGORIES.SHIVA
  },

  // ---------------------------------------------------------
  // 15) Surya Namasakaramulu - 2025
  // ---------------------------------------------------------
  {
    label: 'Surya Namasakaramulu',
    name: 'Surya Namasakaramulu',
    amount: '300',
    // 2025 DATES
    availableDates: [
      createDate('01-26-2025'),
      createDate('02-23-2025'),
      createDate('03-30-2025'),
      createDate('04-27-2025'),
      createDate('05-25-2025'),
      createDate('06-29-2025'),
      createDate('07-27-2025'),
      createDate('08-31-2025'),
      createDate('09-28-2025'),
      createDate('10-26-2025'),
      createDate('11-30-2025'),
      createDate('12-28-2025')
    ],
    category: CATEGORIES.SPECIAL_EVENTS
  },

  // ---------------------------------------------------------
  // The rest remain the same unless your 2025 chart says otherwise
  // ---------------------------------------------------------
  {
    label: 'Dhanurmasa Prasadam',
    name: 'Dhanurmasa Prasadam',
    amount: '250',
    availableDates: [],
    category: CATEGORIES.SPECIAL_EVENTS,
    disabled: true
  },
  {
    name: 'Ganapathi Abhishekam',
    amount: '150',
    availableDates: [],
    category: CATEGORIES.GANESH
  },
  {
    label: 'Radha Saptami Surya Namaskarams',
    name: 'Radha Saptami Surya Namaskarams',
    amount: '500',
    availableDates: [],
    category: CATEGORIES.SPECIAL_EVENTS
  },
  {
    name: 'Laghunyasa Abhishekam',
    amount: '50',
    availableDates: [],
    category: CATEGORIES.SHIVA
  },
  {
    name: 'Shiva Panchamrutha Abhishekam',
    amount: '50',
    availableDates: [],
    category: CATEGORIES.SHIVA
  },
  {
    name: 'Ganapathi Panchamrutha Abhishekam',
    amount: '50',
    availableDates: [],
    category: CATEGORIES.GANESH
  },
  {
    name: 'Shiva Ashtottaram',
    amount: '20',
    availableDates: [],
    category: CATEGORIES.SHIVA
  },
  {
    name: 'Ganapathi Ashtottaram',
    amount: '20',
    availableDates: [],
    category: CATEGORIES.GANESH
  },
  {
    name: 'Ashtottaram',
    amount: '20',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    name: 'Swarna Bilwarchana',
    amount: '1500',
    availableDates: [],
    category: CATEGORIES.SHIVA
  },
  {
    name: 'Special Swarna Bilwarchana',
    amount: '3000',
    availableDates: [],
    category: CATEGORIES.SHIVA
  },
  {
    name: 'Special Swarna kamalam',
    amount: '3000',
    availableDates: [],
    category: CATEGORIES.SHARADA,
    disabled: true
  },
  {
    label: 'Sarva Abharana Bhushitha Swarna kamalam',
    name: 'Sarva Abharana Bhushitha Swarna kamalam',
    amount: '6000',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    label: 'Trishathi Mala Sahita Sarva Abharana Bhushita Swarna kamalam',
    name: 'Trishathi Swarna kamalam',
    amount: '7500',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    label: 'Vahana Pooja',
    name: 'Vahana Pooja',
    amount: '100',
    availableDates: [],
    category: CATEGORIES.OTHERS
  },
  {
    label: 'Sata Rudrabhishekam',
    name: 'Sata Rudrabhishekam',
    amount: '8000',
    availableDates: [
      createDate('10-31-2022'),
      createDate('11-07-2022'),
      createDate('11-14-2022'),
      createDate('11-21-2022'),
    ],
    category: CATEGORIES.SHIVA
  },
  {
    label: 'Bilwarchana',
    name: 'Bilwarchana',
    amount: '250',
    availableDates: [],
    category: CATEGORIES.SHIVA,
    disabled: true
  },
  {
    name: 'Udayasthamana Pooja',
    amount: '3000',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    name: 'Swarna Kamala Pooja',
    amount: '1500',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    name: 'Sri Chakrarchana Sarva Pooja',
    amount: '150',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    name: 'Sahasram',
    amount: '50',
    availableDates: [],
    category: CATEGORIES.SHARADA
  },
  {
    name: 'Sahasra Modaka Ganapathi Homam and Abhishekam',
    amount: '300',
    availableDates: [],
    category: CATEGORIES.GANAPATHI_NAVARATRI,
    disabled: true
  },
  {
    name: 'Durvarchana',
    amount: '250',
    availableDates: [
      createDate('09-23-2023'),
    ],
    category: CATEGORIES.GANAPATHI_NAVARATRI,
    disabled: true
  },
  {
    label: 'Ratha Seva Wooden Chariot (Every Friday/Pournami)',
    name: 'Ratha Seva Wooden Chariot',
    amount: '1500',
    availableDates: [],
    category: CATEGORIES.OTHERS,
    disabled: true
  },
  {
    label: 'Ratha Seva Silver Chariot (Every Friday/Pournami)',
    name: 'Ratha Seva Silver Chariot',
    amount: '2000',
    availableDates: [],
    category: CATEGORIES.OTHERS,
    disabled: true
  },
  {
    label: 'Kailasa Gouri Nomu Kanuka',
    name: 'Kailasa Gouri Nomu Kanuka',
    amount: '800',
    availableDates: [],
    category: CATEGORIES.OTHERS
  },
  {
    label: 'Suvasini Pooja',
    name: 'Suvasini Pooja',
    amount: '200',
    availableDates: [],
    category: CATEGORIES.OTHERS,
    disabled: true
  },

  // ----------------------------------------------------------------------
  // *** 2025 Dasara Navaratri *** (using getContinuousDaysInRange)
  // If your 2025 chart says (for example) 9/30/2025 to 10/08/2025,
  // just replace "09-30-2025" & "10-08-2025" below:
  // ----------------------------------------------------------------------
  {
    label: 'Chandi Parayana 1 Day',
    name: 'Parayana One Day',
    amount: '200',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('10-02-2024'),
        new Date('10-12-2024')
      )
    ],
    category: CATEGORIES.DASARA_NAVARATRI
    // disabled: true
  },
  {
    label: 'Chandi Parayana 9 Days',
    name: 'Parayana Nine Days',
    amount: '1500',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('10-02-2024'),
        new Date('10-12-2024')
      )
    ],
    category: CATEGORIES.DASARA_NAVARATRI,
    // disabled: true
  },
  {
    label: 'Dasara Suvasini Pooja',
    name: 'Dasara Suvasini Pooja',
    amount: '200',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('10-02-2024'),
        new Date('10-12-2024')
      )
    ],
    category: CATEGORIES.DASARA_NAVARATRI,
  },
  {
    label: 'Mahabhishekam (Only on Akshaya Thritiya and Mahalaya Amavasya',
    name: 'Mahabhishekam',
    amount: '12000',
    availableDates: [
      createDate('10-02-2024')
    ],
    category: CATEGORIES.DASARA_NAVARATRI,
    // disabled: true
  },
  {
    label: 'Sarva Seva',
    name: 'Sarva Seva',
    amount: '10000',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('10-02-2024'),
        new Date('10-12-2024')
      )
    ],
    category: CATEGORIES.DASARA_NAVARATRI,
    // disabled: true
  },
  {
    label: 'Dasara Udayastamana Pooja',
    name: 'Dasara Udayastamana Pooja',
    amount: '3000',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('10-02-2024'),
        new Date('10-12-2024')
      )
    ],
    category: CATEGORIES.DASARA_NAVARATRI,
    // disabled: true
  },
  {
    label: 'Dasara Chandi Homa Kanuka',
    name: 'Dasara Chandi Homa Kanuka',
    amount: '500',
    availableDates: [
      createDate('10-11-2024')
    ],
    category: CATEGORIES.DASARA_NAVARATRI,
    // disabled: true
  },
  {
    label:
      'Special Sata Rudrabhishekam (On Mahashivaratri/Shankar Jayanti/ Karthika Masam)',
    name: 'Special SataRudrabhishekam ',
    amount: '15000',
    availableDates: [
      createDate('02-26-2025'),
      createDate('03-08-2024'),
      createDate('02-18-2023'),
      createDate('11-07-2022'),
      createDate('11-14-2022'),
      createDate('11-21-2022'),
    ],
    category: CATEGORIES.SPECIAL_EVENTS,
    disabled: true
  },
  // 51) Sita Rama Kalyanam (On Sri Rama Navami) - unchanged
  {
    label:
      'Sita Rama Kalyanam (On Sri Rama Navami)',
    name: 'Sri Sita Rama Kalyanam ',
    amount: '500',
    availableDates: [
      createDate('03-30-2023'),
      createDate('04-17-2024'),
    ],
    category: CATEGORIES.OTHERS,
    disabled: false
  },
  // 52) Sri Sita Rama Abhishekam - unchanged
  {
    label:
      'Sri Sita Rama Abhishekam',
    name: 'Sri Sita Rama Abhishekam ',
    amount: '100',
    availableDates: [
      ...getContinuousDaysInRange(
        new Date('03-22-2022'),
        new Date('03-30-2022')
      )
    ],
    category: CATEGORIES.OTHERS,
    disabled: false
  },
  // 53) Sri Sita Rama Abhishekam 10 days - unchanged
  {
    label:
      'Sri Sita Rama Abhishekam 10 days',
    name: 'Sri Sita Rama Abhishekam 10 days ',
    amount: '1000',
    availableDates: [
      createDate('03-22-2023'),
    ],
    category: CATEGORIES.OTHERS,
    disabled: false
  },
  // 54) Sri Sita Rama Kalyanam and Anna Prasada Kanuka - unchanged
  {
    label:
      'Sri Sita Rama Kalyanam and Anna Prasada Kanuka',
    name: 'Sri Sita Rama Kalyanam and Anna Prasada Kanuka',
    amount: '10000',
    availableDates: [
      createDate('03-30-2023'),
    ],
    category: CATEGORIES.OTHERS,
    disabled: false
  },
  // 55) Grahana Shanthi Homam - unchanged
  {
    label: 'Grahana Shanthi Homam (Only during eclipse)',
    name: 'Grahana Shanthi Pooja',
    amount: '300',
    availableDates: [
      createDate('10-26-2022'),
      createDate('11-09-2022'),
    ],
    category: CATEGORIES.OTHERS,
    disabled: false
  },
  // 56) Lingodbhava Kala Abhishekam - unchanged
  {
    label: 'Lingodbhava Kala Abhishekam',
    name: 'Lingodbhava Abhishekam',
    amount: '100',
    availableDates: [createDate('02-26-2025')],
    category: CATEGORIES.MAHASHIVARATRI,
    disabled: false
  },
  // 57) Girija Kalyanam - unchanged
  {
    label: 'Girija Kalyanam (Next Day of Shivaratri)',
    name: 'Girija Kalyanam',
    amount: '250',
    availableDates: [createDate('02-27-2024')],
    category: CATEGORIES.MAHASHIVARATRI
  },
  // 58) Swarna Vastra Alankara Seva (Fridays Only) - unchanged
  {
    label: 'Swarna Vastra Alankara Seva (Fridays Only)',
    name: 'Swarna Vastra Alankara Seva',
    amount: '12000',
    availableDates: getContinuousDaysInRange(
      new Date('11-21-2020'),
      new Date('03-31-2021')
    ).filter(date => isFriday(date)),
    category: CATEGORIES.SHARADA
  }
];
